import {
  timeManager,
  audioManager,
  CustomEvents,
  corePhasesManager,
  playersManager,
  gsap,
  modes,
  fpsManager,
  type PlayerInfo,
  cameraManager,
  CameraStates
} from '@powerplay/core-minigames'
import {
  AudioNames,
  DisciplinePhases,
  MaterialsNames,
  type StartPositionsDataObject
} from '../types'
import { StartPhaseManager } from './StartPhase/StartPhase'
import { FinishPhaseManager } from './FinishPhase/FinishPhase'
import { player } from '../entities/athlete/player'
import { RunningPhase } from './RunningPhase/RunningPhase'
import { endManager } from '../EndManager'
import { opponentsManager } from '../entities/athlete/opponent/OpponentsManager'
import {
  gameConfig,
  startConfig
} from '../config'
import { audioHelper } from '../audioHelper/AudioHelper'
import { fakeOpponents } from '../entities/athlete/opponent/FakeOpponents'
import { trainingResultsState } from '@/stores'
import {
  loadingState,
  waitingState
} from '@powerplay/core-minigames-ui-ssm'
import { stateManager } from '../StateManager'

/**
 * Trieda pre spravu faz
 */
export class DisciplinePhasesManager {

  public HELPER_MAN_COLORS = [
    MaterialsNames.npcStart1,
    MaterialsNames.npcStart2,
    MaterialsNames.npcStart3
  ]

  /** aktualna faza */
  public actualPhase = 0

  /** pokus */
  public attempt = 1

  /** Data pre startovacie pozicie */
  public dataForPlayersStartPositions: StartPositionsDataObject = {}

  /** Data pre startovacie poradie */
  public dataForPlayersStartOrder: StartPositionsDataObject = {}

  /** Faza startu */
  public phaseStart!: StartPhaseManager

  /** Faza jazdy */
  public phaseRunning!: RunningPhase

  /** faza konca */
  public phaseFinish!: FinishPhaseManager

  /** tween na nastartovanie fazoveho managera */
  private startDisciplineTween!: gsap.core.Tween

  /**
   * Vytvorenie a nastavenie veci
   */
  public create(): void {

    this.createAllPhases()

  }

  /**
   * Vytvorenie menegerov faz
   */
  public createAllPhases(): void {

    this.phaseStart = new StartPhaseManager(() => {

      this.startDisciplinePhase(DisciplinePhases.running)

    })

    this.phaseRunning = new RunningPhase(() => {

      this.startDisciplinePhase(DisciplinePhases.finish)

    })

    this.phaseFinish = new FinishPhaseManager(() => {

      this.actualPhase++
      console.log('dispatch end')
      if (DisciplinePhases[this.actualPhase]) {

        console.log('dispatch end')
        window.dispatchEvent(new CustomEvent(CustomEvents.finishDisciplinePhase))

        waitingState().isWaiting = true

      }

    })

  }

  /**
   * Zistenie, ci jedna z faza je aktualna faza
   * @param phase - Pole faz na skontrolovanie
   * @returns True, ak je jedna z faz aktualna
   */
  public oneOfPhaseIsActual(phases: DisciplinePhases[]): boolean {

    return phases.includes(this.actualPhase)

  }

  public getActualPhase(): DisciplinePhases {

    return this.actualPhase

  }

  /**
   * Spustenie fazy
   * @param phase - Cislo fazy
   */
  public startDisciplinePhase(phase: DisciplinePhases): void {

    this.actualPhase = phase
    if (phase === DisciplinePhases.start) this.phaseStart.startPhase()
    if (phase === DisciplinePhases.running) this.phaseRunning.startPhase()
    if (phase === DisciplinePhases.finish) this.phaseFinish.startPhase()

  }

  /**
   * Update aktualnej fazy kazdy frame
   */
  public update(): void {

    if (this.actualPhase === DisciplinePhases.start) this.phaseStart.update()
    if (this.actualPhase === DisciplinePhases.running) this.phaseRunning.update()
    if (this.actualPhase === DisciplinePhases.finish) this.phaseFinish.update()

  }

  /**
   * rekurzivne ukoncime vsetky fazy
   */
  public disciplinePrematureEnd = async (): Promise<void> => {

    opponentsManager.getOpponents().forEach(opponent => {

      opponent.setVisibilityAthlete(false)

    })
    player.setVisibilityAthlete(false)

    this.actualPhase = DisciplinePhases.end

    audioManager.stopAllAudio()
    audioManager.play(AudioNames.audienceFinish)
    audioHelper.stopBreathing()
    audioHelper.stopHeartbeat()

    corePhasesManager.disciplineActualAttempt = corePhasesManager.disciplineAttemptsCount

    // kvoli dennej lige musime dat naspat originalnych superov, aby sa zobrazili v konecnej listine
    if (modes.isDailyLeague()) opponentsManager.setOriginalData()
    playersManager.setStandings(3)
    console.log('STANDINGS', playersManager.getStandings())

    fpsManager.pauseCounting()

    const isFinished = false

    // posleme udaje
    endManager.sendLogEnd()
    endManager.sendSaveResult()

    // reset states
    stateManager.resetPinia()
    waitingState().isWaiting = true
    if ((!isFinished || corePhasesManager.firstInstructions) && !modes.isTrainingMode()) {

      trainingResultsState().isDisabledPlayAgain = true

    }
    // stopneme vsetky animacne callbacky
    if (player.animationsManager) player.animationsManager.removeCallbacksFromAllAnimations()

  }

  /**
   * Nastartovanie disciplinoveho fazoveho managera
   */
  public setStartPhase = (): void => {

    // v treningu musime spravit nejake upravy, aby vsetko fungovalo ako malo
    if (modes.isTrainingMode()) {

      player.updateBeforePhysics(disciplinePhasesManager.actualPhase)
      player.updateCameraConfigOnStart(CameraStates.intro)
      if (gameConfig.cameraConfig.enabled) player.setGameCameraSettings()
      cameraManager.setState(CameraStates.intro)
      cameraManager.playTween(false)

    }


    if (modes.isTutorial() && corePhasesManager.disciplineActualAttempt === 1) {

      this.startStartPhase()
      return

    }

    // musime tu dat mensi delay, lebo mozeme skipovat este nejake fazy predtym
    this.startDisciplineTween = gsap.to({}, {
      duration: modes.isTutorial() ? 0.01 : 0.2,
      onComplete: () => {

        this.startStartPhase()

      }
    })

  }

  /**
   * Spustenie start phase
   */
  private startStartPhase(): void {

    if (modes.isTrainingMode()) {

      // dame prec loading
      const loadingStateInstance = loadingState().$state
      const newState = { ...loadingStateInstance,
        showLoading: false }
      loadingState().$patch(newState)

    }

    const phase = DisciplinePhases.start
    this.startDisciplinePhase(phase)

  }

  /**
   * resetovanie hry
   * @param resetAttempts - Ci ma byt tvrdy reset aj s poctom pokusov
   */
  public resetAttempt(resetAttempts = false): void {

    if (resetAttempts) {

      this.attempt = 0

    }

    this.attempt += 1


    this.actualPhase = 0
    timeManager.reset()
    console.log('reseting')
    player.reset()
    opponentsManager.reset()
    this.resetStartingOrder()

    if (resetAttempts) return
    this.startDisciplinePhase(DisciplinePhases.start)

  }

  /**
   * Nastavenie startingOrder nanovo
   */
  public resetStartingOrder(): void {

    this.setStartPositionsForPlayers()
    player.startingOrder = this.dataForPlayersStartOrder[playersManager.getPlayer().uuid]
    opponentsManager.getOpponents().forEach(opponent => {

      if (this.dataForPlayersStartOrder[opponent.uuid] !== undefined) {

        opponent.startingOrder = this.dataForPlayersStartOrder[opponent.uuid]

      }

    })

  }

  /**
   * Nastavenie startovacich pozicii pre vsetkych hracov
   */
  public setStartPositionsForPlayers(): void {

    // musime si presunut data do noveho pola, aby sa neprepisovali hodnoty z packages
    const players: PlayerInfo[] = []
    if (modes.isDailyLeague() || modes.isTutorial() || modes.isTrainingMode()) {

      players.push(playersManager.getPlayer())
      players.push(...fakeOpponents.map((val) => val))

    } else {

      players.push(...playersManager.players.map((val) => val))

    }

    // zoradime si hracov podla RP a prejdeme si vsetkych hracov a podla uuid nastavime pole
    players.sort((a: PlayerInfo, b: PlayerInfo): number => {

      if (a.rankingPoints === undefined || b.rankingPoints === undefined) return -1

      return b.rankingPoints - a.rankingPoints

    })

    let pathIndexes = [1, 2, 3, 3, 2, 1]

    let startingOrder = [0, 1, 2, 3, 4, 5]

    if (modes.isTutorial()) {

      const playerIndex = players.findIndex(p => {

        return p.playable

      })
      const cache = startingOrder[startingOrder.length - 1]
      startingOrder[startingOrder.length - 1] = startingOrder[playerIndex]
      startingOrder[playerIndex] = cache

    } else {

      if (modes.isEventBossFight()) {

        pathIndexes = [1, 2]
        startingOrder = [0, 1]

      }
      startingOrder.sort(() => {

        return Math.random() - 0.5

      })

    }
    this.HELPER_MAN_COLORS.sort(() => Math.random() - 0.5)

    players.forEach((playerInfo: PlayerInfo, index: number) => {

      this.dataForPlayersStartPositions[playerInfo.uuid] = pathIndexes[index]
      this.dataForPlayersStartOrder[playerInfo.uuid] = startingOrder[index]

    })
    console.warn(this.dataForPlayersStartPositions, this.dataForPlayersStartOrder, startingOrder, modes)
    if (startConfig.playersStartOrder.active) {

      this.dataForPlayersStartOrder = startConfig.playersStartOrder.data

    }


  }

  /**
   * Reinstancovanie manazerov
   */
  public reset(): void {

    this.dataForPlayersStartPositions = {}
    this.setStartPositionsForPlayers()
    this.createAllPhases()
    this.resetAttempt(true)
    endManager.prematureEnded = false

  }

}

export const disciplinePhasesManager = new DisciplinePhasesManager()
