import {
  corePhasesManager,
  modes,
  playersManager,
  requestManager,
  type TrainingDataFromResultsRequest,
  trainingManager,
  gameStats,
  minigameConfig,
  type PlayerInfo
} from '@powerplay/core-minigames'
import { tutorialObjectives } from './modes/tutorial/TutorialObjectives'
import {
  TutorialObjectiveIds,
  type OpponentResult,
  type SaveResultsDataToSend
} from './types'
import { player } from './entities/athlete/player'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import { gameConfig } from './config'
import { trainingResultsState } from '@/stores/trainingResultsState'

/**
 * Trieda pre koniec discipliny
 */
export class EndManager {

  /** ci uz bol result poslany alebo nie */
  private resultSent = false

  /** UUID vitaza */
  private winnerUuid = ''

  /** Cas vitaza */
  private winnerTime = minigameConfig.dnfValue

  /** ci sa skoncilo predcasne */
  public prematureEnded = false

  /** Ci bol hrac diskvalifikovany */
  public wasDsq = false

  /** rychlost pri prechode ciela */
  public finishSpeed = 0

  /** pocet obehnuti */
  public overtakingCount = 0

  /**
   * Zistenie, ci je atlet v cieli vitazom podla frame-u
   * @param uuid - Uuid hraca
   * @returns True, ak ano
   */
  public isWinnerInFinish(uuid: string): boolean {

    return this.winnerUuid === uuid

  }

  /**
   * Zistenie, ci uz je nejaky atlet v cieli
   * @returns True, ak ano
   */
  public isSomeoneInFinish(): boolean {

    return this.winnerTime !== minigameConfig.dnfValue

  }

  /**
   * Nastavenie potencialneho vitaza v cieli
   * @param uuid - uuid potencialneho vitaza
   * @param time - cas potencialneho vitaza
   */
  public setPotentialWinnerInFinish(uuid: string, time: number): void {

    if (time < this.winnerTime) {

      this.winnerTime = time
      this.winnerUuid = uuid

    }

  }

  /**
   * Poslanie requestu pre konecne logovanie
   */
  public sendLogEnd(): void {

    // ak uz mame nastavene, tak uz viac nenastavujeme
    if (Object.keys(gameStats.getDisciplineDataToLog()).length > 0) return

    const sendTimeZero = playersManager.dnf || modes.isTutorial() || modes.isTrainingMode()

    // zaznamename nejake info pre logy
    gameStats.setDisciplineDataToLog({
      time: sendTimeZero ? 0 : playersManager.players[0].resultsArr?.[
        corePhasesManager.disciplineActualAttempt - 1
      ].main || 0,
      dnf: playersManager.dnf,
      dsq: this.wasDsq,
      playerPosition: playersManager.getPlayerActualPosition(),
      trainingTasks: modes.isTrainingMode() ?
        trainingManager.getTrainingTasks().map(task => task.value) :
        [],
      tutorialData: modes.isTutorial() ? this.getTutorialLogs() : []
    })

    console.log('LOG to send', gameStats.getDisciplineDataToLog())

  }

  /**
   * ziskame objekt tutorialovych logov
   *
   * @returns - objekt tutorialovych logov
   */
  private getTutorialLogs(): (number | boolean)[] {

    /**
     * completed - Ci hrac uspesne dokoncil vsetky ulohy [boolean]
     * exited - Ci bol ukonceny tutorial [boolean]
     */

    return [
      disciplinePhasesManager.attempt,
      tutorialObjectives.checkIfObjectivePassedById(TutorialObjectiveIds.requiredSpeed),
      player.isEnd ? playersManager.getPlayerActualPosition(player.uuid) : -1,
      tutorialObjectives.isAllObjectivesPassed(),
      this.prematureEnded
    ]

  }

  /**
   * Vybratie dat a poslanie do funkcie z core-minigames
   */
  public sendSaveResult(): void {

    // ak uz bol result poslany, neposielame ho znova
    if (this.resultSent) return

    this.resultSent = true

    // TODO TEMP - zatial takto, ked bude hotovy tutorial, tak sa to bude posielat tam
    requestManager.sendTutorialRequest()
    if (modes.isTutorial()) return

    const opponentsResults = [] as OpponentResult[]
    playersManager.players.filter((playerInfo: PlayerInfo, index: number) => {

      return !playerInfo.playable && index <= gameConfig.numberOfOpponents

    }).forEach((playerInfo: PlayerInfo) => {

      opponentsResults.push({
        uuid: playerInfo.uuid,
        time: playerInfo.resultsArr?.[0].main || minigameConfig.dnfValue
      })

    })

    // musime vybrat to lepsie umiestnenie a iba to budeme riesit (kedze su zoradeni, zoberieme prveho)
    const playerStanding = playersManager.getStandings().filter(standing => standing.uuid === player.uuid)
    const resultTime = Number(playerStanding[0]?.resultsFull?.[0]?.main) ?? 0

    const data: SaveResultsDataToSend = {
      time: resultTime,
      positions: playersManager.getPlayersPositions(),
      dnf: playersManager.dnf,
      dsq: this.wasDsq,
      opponentsResults: modes.isTrainingMode() ? [] : opponentsResults,
      finishSpeed: modes.isTutorial() ? '0' : (this.finishSpeed * 3.6).toFixed(0),
      overtakingCount: modes.isTutorial() ? 0 : this.overtakingCount,
    }

    if (modes.isTrainingMode()) {

      data.trainingResults = trainingManager.getTrainingTasks().map(task => task.value)

    }

    console.log('data to send', data)

    requestManager.sendSaveResultsRequest(
      (dataCallback: TrainingDataFromResultsRequest | unknown) => {

        console.log('saveRequest Successful')

        if (modes.isTrainingMode()) {

          trainingResultsState().$patch({
            data: dataCallback as TrainingDataFromResultsRequest,
            bestScore: trainingManager.bestScore,
            dataSet: true
          })

        }

      },
      JSON.stringify(data)
    )

  }

  /**
   * Reset result
   */
  public reset(): void {

    this.resultSent = false
    this.winnerTime = minigameConfig.dnfValue
    this.winnerUuid = ''

  }

}

export const endManager = new EndManager()
